import type { IWalletWhitelistAddress } from "@aspen/model";
import { ICustomerType } from "@aspen/model";
import { Modal } from "antd";
import styles from "@aspen/theme/Wallet.module.less";
import { i18nUtil } from "@aspen/libs";
import React from "react";

interface IProps {
  visible: boolean;
  whitelistInfo?: IWalletWhitelistAddress;
  handleCancel: () => void;
}

const ModalWhitelistDetail: React.FC<IProps> = (props) => {
  const { visible, whitelistInfo, handleCancel } = props;
  const intl = i18nUtil.t();
  const countryNationality = JSON.parse(whitelistInfo?.country || "{}")?.nationality;

  return (
    <Modal
      destroyOnClose
      wrapClassName={styles.modal}
      title={
        whitelistInfo?.customerType === ICustomerType.INDIVIDUAL.toUpperCase()
          ? intl["wallet.withdraw.white.list.add.address.individual.title"]
          : intl["wallet.withdraw.white.list.add.address.institution.title"]
      }
      open={visible}
      centered
      footer={null}
      width={480}
      onCancel={handleCancel}>
      <div className={styles.middleArea}>
        {whitelistInfo?.customerType === ICustomerType.INDIVIDUAL.toUpperCase() ? (
          <>
            <p>
              <span className={styles.middleKey}>{intl["adgm.wallet.input.label.first.name"]}</span>
              <span className={styles.middleValue}>{whitelistInfo?.firstName}</span>
            </p>
            <p>
              <span className={styles.middleKey}>{intl["adgm.wallet.input.label.last.name"]}</span>
              <span className={styles.middleValue}>{whitelistInfo?.lastName}</span>
            </p>
          </>
        ) : (
          <p>
            <span className={styles.middleKey}>{intl["adgm.wallet.input.label.company.name"]}</span>
            <span className={styles.middleValue}>{whitelistInfo?.customerName}</span>
          </p>
        )}

        <p>
          <span className={styles.middleKey}>{intl["adgm.wallet.detail.address.line1"]}</span>
          <span className={styles.middleValue}>
            {JSON.parse(whitelistInfo?.location || "[]")?.[0] || "--"}
          </span>
        </p>
        <p>
          <span className={styles.middleKey}>{intl["adgm.wallet.detail.address.line2"]}</span>
          <span className={styles.middleValue}>
            {JSON.parse(whitelistInfo?.location || "[]")?.[1] || "--"}
          </span>
        </p>
        <p>
          <span className={styles.middleKey}>{intl["adgm.wallet.input.label.city"]}</span>
          <span className={styles.middleValue}>{whitelistInfo?.city || "--"}</span>
        </p>
        <p>
          <span className={styles.middleKey}>{intl["adgm.wallet.input.label.country"]}</span>
          <span className={styles.middleValue}>{countryNationality || "--"}</span>
        </p>
      </div>
    </Modal>
  );
};

export default React.memo(ModalWhitelistDetail);
