import React, { useEffect, useState } from "react";
import { Table, Row, Col, Spin, Tooltip } from "antd";
import styles from "@aspen/theme/Wallet.module.less";
import { transformTime, convertUSD2USDC, i18nUtil } from "@aspen/libs";
import type { IWalletWhitelistAddress } from "@aspen/model";
import { ICustomerType, WALLET_WHITELIST_STATUS } from "@aspen/model";
import { getWhitelistAddress } from "@aspen/services";
import { WithEmptyContent } from "@aspen/ui";
import { ModalWhitelistDetail } from "@aspen/widgets";
import { InfoCircleOutlined } from "@ant-design/icons";

const StatusColor = {
  CREATED: "#fff",
  APPROVED: "#07C160",
  REJECTED: "#FA5151",
  DELETED: "#fff"
};

const WhitelistHistory = () => {
  const intl = i18nUtil.t();
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [detailModalVisible, setDetailModalVisible] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<IWalletWhitelistAddress>();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    getWhitelistAddress({})
      .then((res) => {
        if (res?.code == "0") {
          setDataSource(res?.data);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      title: intl["column.date"],
      dataIndex: "created",
      render: (value: number) => <span>{transformTime(value)}</span>
    },
    { title: intl["column.name"], dataIndex: "customerName" },
    {
      title: intl["column.denomination"],
      dataIndex: "currency",
      render: (value: string) => <span>{convertUSD2USDC(value)}</span>
    },
    {
      title: intl["column.network"],
      dataIndex: "chainName"
    },
    {
      title: intl["column.address"],
      width: 360,
      textWrap: "word-break",
      render: (_, record: IWalletWhitelistAddress) => (
        <>
          <span>{record.address}</span>
          {record?.currency === "XRP" && (
            <Tooltip title={record?.memo}>
              <InfoCircleOutlined style={{ paddingLeft: "5px", cursor: "pointer" }} />
            </Tooltip>
          )}
        </>
      )
    },
    {
      title: intl["column.note"],
      render: (_, record: IWalletWhitelistAddress) => <span>{record?.vaspName || "--"}</span>
    },
    {
      title: "",
      render: (_, record: IWalletWhitelistAddress) => {
        return (
          <>
            {record?.customerType === ICustomerType.INDIVIDUAL.toUpperCase() ? (
              <span
                className={styles.individual}
                onClick={() => {
                  setCurrentItem(record);
                  setDetailModalVisible(true);
                }}>
                {ICustomerType.INDIVIDUAL}
              </span>
            ) : (
              <span
                className={styles.institution}
                onClick={() => {
                  setCurrentItem(record);
                  setDetailModalVisible(true);
                }}>
                {ICustomerType.INSTITUTION}
              </span>
            )}
          </>
        );
      }
    },
    {
      title: intl["column.status"],
      dataIndex: "status",
      width: 100,
      render: (value: string) => (
        <span
          style={{
            color: StatusColor?.[value]
          }}>
          {intl[WALLET_WHITELIST_STATUS[value]]}
        </span>
      )
    }
  ];

  return (
    <section className={`${styles.wallet} ` + `customer`}>
      <div className="customerTop">
        <Row className="top">
          <Col span={24}>
            <p className="title">{intl["router.title.wallet.withdraw.whitelist.history"]}</p>
          </Col>
        </Row>
      </div>
      <div className={styles.whitelistHistory}>
        <div className="section" style={{ width: "100%", marginTop: 0 }}>
          <div className={styles.history} style={{ marginTop: 0 }}>
            <Spin spinning={loading}>
              <WithEmptyContent showEmpty>
                <Table
                  className={styles.table}
                  rowKey={(record: any) => record?.id}
                  bordered={false}
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                />
              </WithEmptyContent>
            </Spin>
          </div>
        </div>
      </div>
      {detailModalVisible ? (
        <ModalWhitelistDetail
          visible={detailModalVisible}
          handleCancel={() => setDetailModalVisible(false)}
          whitelistInfo={currentItem}
        />
      ) : null}
    </section>
  );
};

export const PageAdgmWhitelistHistory = WhitelistHistory;
