import React, { useEffect, useState } from "react";
import { ICustomerType, WALLET_WHITELIST_TAB_TYPE, WhitelistStatus } from "@aspen/model";
import type { IWalletWhitelistAddress } from "@aspen/model";

import { useRouter } from "next/router";
import styles from "@aspen/theme/Wallet.module.less";
import { Row, Col, Button, Spin, Tabs, Table, Modal, Tooltip } from "antd";
import {
  GA_EVENT_NAME,
  WALLET_PATHS,
  convertUSD2USDC,
  getQueryValue,
  i18nUtil,
  reportEvent
} from "@aspen/libs";
import Image from "next/image";
import { deleteWhitelistAddress, getWhitelistAddress } from "@aspen/services";
import { message, ModalBase } from "@aspen/ui";
import ModalWhitelistDetail from "@aspen/widgets/wallet/modal/ModalWhitelistDetail";
import { InfoCircleOutlined } from "@ant-design/icons";

interface IProps {}

export function PageAdgmWhitelistAddressManage(props: IProps) {
  const tab = getQueryValue("tab");
  const intl = i18nUtil.t();
  const router = useRouter();
  const tabItems = [
    {
      label: intl["wallet.withdraw.white.list.tab.whitelisted"],
      key: WALLET_WHITELIST_TAB_TYPE.Whitelist
    },
    {
      label: intl["wallet.withdraw.white.list.tab.reviewing"],
      key: WALLET_WHITELIST_TAB_TYPE.InReview
    }
  ];
  const [loading, setLoading] = useState<boolean>(true);
  const [currentTab, setCurrentTab] = useState<string>(tab || WALLET_WHITELIST_TAB_TYPE.Whitelist);
  const [dataSource, setDataSource] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [detailModalVisible, setDetailModalVisible] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<IWalletWhitelistAddress>();
  const columns = [
    {
      title: intl["column.name"],
      width: 175,
      render: (_, record: IWalletWhitelistAddress) => <span>{record?.customerName}</span>
    },
    {
      title: intl["column.denomination"],
      dataIndex: "currency",
      render: (value: string) => convertUSD2USDC(value)
    },
    {
      title: intl["column.network"],
      dataIndex: "chainName"
    },
    {
      title: intl["column.address"],
      width: 360,
      render: (_, record: IWalletWhitelistAddress) => (
        <p>
          <span>{record.address}</span>
          {record?.currency === "XRP" && (
            <Tooltip title={record?.memo}>
              <InfoCircleOutlined style={{ paddingLeft: "5px", cursor: "pointer" }} />
            </Tooltip>
          )}
        </p>
      )
    },
    {
      title: intl["column.note"],
      render: (_, record: IWalletWhitelistAddress) => <span>{record?.vaspName || "--"}</span>
    },
    {
      title: "",
      render: (_, record: IWalletWhitelistAddress) => {
        return (
          <>
            {record?.customerType === ICustomerType.INDIVIDUAL.toUpperCase() ? (
              <span
                className={styles.individual}
                onClick={() => {
                  setCurrentItem(record);
                  setDetailModalVisible(true);
                }}>
                {ICustomerType.INDIVIDUAL}
              </span>
            ) : (
              <span
                className={styles.institution}
                onClick={() => {
                  setCurrentItem(record);
                  setDetailModalVisible(true);
                }}>
                {ICustomerType.INSTITUTION}
              </span>
            )}
          </>
        );
      }
    },
    ...(currentTab === WALLET_WHITELIST_TAB_TYPE.Whitelist
      ? [
          {
            title: intl["column.action"],
            render: (_, record: IWalletWhitelistAddress) => {
              return (
                <div>
                  <Button
                    onClick={() => {
                      reportEvent({
                        moduleName: GA_EVENT_NAME.whithdraw.delete
                      });
                      setCurrentItem(record);
                      setDeleteModalVisible(true);
                    }}>
                    {intl["wallet.withdraw.white.list.delete.address.title"]}
                  </Button>
                </div>
              );
            }
          }
        ]
      : [])
  ];
  useEffect(() => {
    if (currentTab) {
      fetchListData();
    }
  }, [currentTab]);

  const handleChangeTabs = (value: string) => {
    setCurrentTab(value);
  };
  const fetchListData = () => {
    setLoading(true);
    const param = {
      statuses:
        currentTab === WALLET_WHITELIST_TAB_TYPE.Whitelist
          ? WhitelistStatus.APPROVED
          : WhitelistStatus.CREATED
    };
    getWhitelistAddress(param)
      .then((res) => {
        if (res?.code == "0") {
          setDataSource(res?.data ?? []);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const deleteAddress = () => {
    if (!currentItem) return;
    const params = {
      addressId: currentItem.id
    };
    reportEvent({
      moduleName: GA_EVENT_NAME.whithdraw.deleteAddress,
      detailParams: params
    });
    deleteWhitelistAddress(params).then((res) => {
      if (res?.code == "0") {
        setTimeout(() => {
          fetchListData();
        }, 1000);
        message.success(intl["wallet.withdraw.white.list.delete.address.success"]);
      }
    });
  };
  const renderEmptyView = () => {
    return (
      <div className={styles.emptyView}>
        <Image
          unoptimized
          alt=""
          width="88"
          height="88"
          src={require("@aspen/assets/images/addressWhitelist/noAddress.webp").default}
        />
        <p>{intl["wallet.withdraw.select.address.noAddress"]}</p>
        <div className={styles.addAddress}>
          <span
            onClick={() => {
              router.push(WALLET_PATHS.WALLET_WITHDRAW_ADD_ADDRESS);
            }}>
            {intl["wallet.withdraw.white.list.bottom.modal.noAddress.btn"]}
          </span>
        </div>
        <p>{intl["wallet.withdraw.white.list.empty.text3"]}</p>
      </div>
    );
  };

  return (
    <section className={`${styles.withdrawAddressManage} ` + `customer`}>
      <div className="customerTop">
        <Row className="top">
          <Col span={24}>
            <div className={styles.topTitleArea}>
              <div className={styles.leftTitleArea}>
                <span className="title">{intl["wallet.withdraw.white.list.title"]}</span>
              </div>
              <div className={styles.rightTitleArea}>
                <Button
                  type="primary"
                  className={styles.allBtn}
                  style={{
                    minWidth: 109
                  }}
                  onClick={() => {
                    reportEvent({ moduleName: GA_EVENT_NAME.whithdraw.previewHistory });
                    router.push(WALLET_PATHS.WALLET_WITHDRAW_WHITELIST_HISTORY);
                  }}>
                  {intl["button.withdraw.white.list.history"]}
                </Button>
                <Button
                  type="primary"
                  className={styles.allBtn}
                  onClick={() => {
                    reportEvent({ moduleName: GA_EVENT_NAME.whithdraw.previewAdd });
                    router.push(WALLET_PATHS.WALLET_WITHDRAW_ADD_ADDRESS);
                  }}>
                  {intl["button.withdraw.white.list.add.address"]}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="section">
        <Spin spinning={loading}>
          <Tabs
            items={tabItems}
            className={styles.tab}
            activeKey={currentTab}
            onChange={handleChangeTabs}
          />

          {dataSource?.length ? (
            <div className={styles.tableView}>
              <Table
                className={styles.table}
                bordered={false}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
              />
            </div>
          ) : (
            renderEmptyView()
          )}
        </Spin>
      </div>
      {deleteModalVisible ? (
        <ModalBase
          open={deleteModalVisible}
          title={intl["wallet.withdraw.white.list.delete.address.title"]}
          onOk={() => {
            reportEvent({ moduleName: GA_EVENT_NAME.whithdraw.confirmDelete });
            setDeleteModalVisible(false);
            deleteAddress();
          }}
          okText={intl["button.confirm"]}
          onCancel={() => {
            setDeleteModalVisible(false);
          }}>
          <div>{intl["wallet.withdraw.white.list.delete.address.content"]}</div>
        </ModalBase>
      ) : null}
      {detailModalVisible ? (
        <ModalWhitelistDetail
          visible={detailModalVisible}
          handleCancel={() => setDetailModalVisible(false)}
          whitelistInfo={currentItem}
        />
      ) : null}
    </section>
  );
}
